import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import isSubpage from '../utils/isSubpage';
import Subpage from '../components/Subpage';
import styles from './changelog.module.scss';

const DATA = [
  {
    content:
      '3 new subjects (Workout, Hands, Construction) - 18 new illustrations have been added for FREE for those who have already bought Whoooa!',
    date: '11.03.2021',
    title: '18 new illustrations added',
  },
  {
    content:
      '3 new subjects (Gaming, Pandemic/Medical, Teaching) - 18 new illustrations have been added for FREE for those who have already bought Whoooa!',
    date: '22.09.2020',
    title: '18 new illustrations added',
  },
  {
    date: '14.07.2020',
    title: 'Version 1.0 released',
  },
];

const ChangelogPage = ({ location }) => (
  <Layout isSubpage={isSubpage(location.pathname)}>
    <Seo title="Changelog - Whoooa! Illustrations" />
    <Subpage className={styles.changelog} title="Changelog">
      {DATA.map(({ content, date, title }) => (
        <article className={styles.changelog__post}>
          <header className={styles.changelog__heading}>
            <h2 className={styles.changelog__title}>{title}</h2>
            <date className={styles.changelog__date}>{date}</date>
            {content && <p className={styles.changelog__content}>{content}</p>}
          </header>
        </article>
      ))}
    </Subpage>
  </Layout>
);

export default ChangelogPage;
